import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { RootState } from "../redux/reducers";
import { RoutePaths } from "./../Constants/routeConstants";
const SwitchPage = lazy(() => import("../Components/SwitchPage"));
const PageNotFound = lazy(() => import("../Components/PageNotFound"));
const Home = lazy(() => import('./../Components/Home'));
const FileManagerPage = lazy(() => import('./../Components/TaskRecordFileManager'));

const AuthRoutes = () => {

  const baseUrl = useSelector((state: RootState) => state.auth.baseUrl);

  return (
    <Routes>
      {baseUrl === "" && (
        <>
          <Route
            path={RoutePaths.Dashboard}
            element={
              <Suspense fallback={null}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path={RoutePaths.SwitchPage}
            element={
              <Suspense fallback={null}>
                <SwitchPage />
              </Suspense>
            } />
          <Route
            path={RoutePaths.PageNotFound}
            element={
              < Suspense fallback={null} >
                <PageNotFound />
              </Suspense >
            }
          />
          <Route path={RoutePaths.FileManager} element={<Suspense fallback={null}>
            <FileManagerPage />
          </Suspense>} />
        </>
      )}
    </Routes >
  );
};

export default AuthRoutes;
