import { ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./Common/Loader";
import Toasters from "./Common/Toaster";
import Dashboard from "./Components/Dashboard";
import { RootState } from "./redux/reducers";
import UnAuthRoutes from "./Routes/unAuthRoutes";
import { THEME } from "./Constants/contants";
import { analytics } from "./analytics";
import { APP_INITIALIZATION } from "./analytics/constants";
import { isPhraseTranslationUpdateRequired } from "./Localization/PharseTranslation";
import { useTranslation } from "react-i18next";
import { getDefaultSelectedLanguage } from "./Localization/Wrapper";

const App = () => {
  const { authToken, userName } = useSelector((state: RootState) => state.auth);
  const { lang } = useSelector((state: RootState) => state.language);
  const { i18n } = useTranslation();

  useEffect(() => {
    analytics.trackEvent(APP_INITIALIZATION);

    async function fetchAPI() {
      await isPhraseTranslationUpdateRequired(i18n);
      getDefaultSelectedLanguage(i18n);
    }
    fetchAPI();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    if (userName) {
      analytics.setUser(userName);
    }
  }, [userName]);

  return (
    <ThemeProvider theme={THEME}>
      <Loader />
      <Toasters />
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={(!authToken && <UnAuthRoutes />) || <Dashboard />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
