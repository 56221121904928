const apiEndpoints = {
    sendOtpEmailUrl: '/otp/send/email',
    verifyOtpUrl: '/authenticate/user',
    uploadTemplateUrl: '/bulk/upload',
    downloadTemplateUrl: '/bulk/download',
    downloadHistoryUrl: '/bulk/history',
    uploadTrackerUrl: '/bulk/uploadTracker',
    logout: '/logoff',
    logoutAll: '/logoff/all',
    getFileDirectory: '/file/directory',
    getFile: '/file',
}

export const baseUrl = process.env.REACT_APP_BASEURL!
export default apiEndpoints
